import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";

import {setIsDrawingRoom} from "../roomsSlice";

import useSelectedRoom from "../hooks/useSelectedRoom";

import {Box, Typography, IconButton, CircularProgress} from "@mui/material";
import {Pentagon} from "@mui/icons-material";

export default function ButtonEditRoomInPdf({caplaEditor, loading}) {
  const dispatch = useDispatch();

  // data

  const room = useSelectedRoom();
  const isDrawingRoom = useSelector((s) => s.rooms.isDrawingRoom);

  // effect

  useEffect(() => {
    if (room?.id) {
      dispatch(setIsDrawingRoom(false));
    }
  }, [room?.id]);

  // handlers

  function handleClick() {
    dispatch(setIsDrawingRoom(!isDrawingRoom));
    //
    if (!isDrawingRoom) {
      caplaEditor.editorPdf.triggerDrawPolyline();
      caplaEditor.editorPdf.annotationsManager.setDrawingRoom(true);
    } else {
      caplaEditor.editorPdf.annotationsManager.stopDrawingRoom();
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        p: 0.5,
        pl: 1,
        color: !isDrawingRoom ? "inherit" : "grey.400",
      }}
    >
      <Typography sx={{mr: 1}} variant="body2">
        {room?.name}
      </Typography>
      {!loading && (
        <IconButton color="inherit" size="small" onClick={handleClick}>
          <Pentagon fontSize="small" />
        </IconButton>
      )}
      {loading && <CircularProgress size={20} sx={{color: "common.white"}} />}
    </Box>
  );
}
