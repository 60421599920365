export default function stateToRemoteSurvey(survey) {
  const _survey = {...survey};

  // scene
  _survey.scene = survey.sceneId;
  delete _survey.sceneId;

  // scope
  _survey.scope = survey.scopeId;
  delete _survey.scopeId;

  // assignedTo
  _survey.assignedTo = survey.assignedToId;

  // createdBy
  _survey.createdBy = survey.createdById;

  return _survey;
}
