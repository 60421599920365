import {useEffect, useState} from "react";

import {InputBase, Box, IconButton} from "@mui/material";
import theme from "Styles/theme";
import {lighten} from "@mui/material/styles";
import {Search as SearchIcon, Close} from "@mui/icons-material";

import useTranslation from "Features/translations/useTranslation";
import {capitalizeStart} from "Features/translations/utils";

export default function SearchBarObjectsV2({
  searchText,
  onSearchTextChange,
  onFocus,
  onBlur,
  onClose,
  bgcolor,
}) {
  const {t} = useTranslation("common");
  const placeholderString = capitalizeStart(t("common:search"));

  let text = searchText ?? "";

  const [debouncedText, setDebouncedText] = useState(text);
  useEffect(() => {
    setDebouncedText(text);
  }, [text]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (debouncedText !== searchText) {
        // imortant to avoid infinite loop
        onSearchTextChange(debouncedText);
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedText, onSearchTextChange]);

  const color = "inherit";
  bgcolor = bgcolor ?? theme.palette.common.white;

  // handlers

  function handleChange(e) {
    let newText = e.target.value;
    setDebouncedText(newText);
  }

  // handlers

  function handleFocus() {
    if (onFocus) onFocus();
  }
  function handleBlur() {
    if (onBlur) onBlur();
  }
  function handleClose() {
    onSearchTextChange("");
    if (onClose) onClose();
  }

  return (
    <Box
      sx={{
        position: "relative",
        borderRadius: (theme) => theme.shape.borderRadius,
        bgcolor,
        "&:hover": {
          backgroundColor: lighten(bgcolor, 0.1),
        },
        width: 1,
        height: "30px",
        px: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <InputBase
        fullWidth
        placeholder={`${placeholderString} ...`}
        value={debouncedText}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        fontSize={12}
        inputProps={{size: "small", sx: {m: 0, py: 0.5, fontSize: 12}}}
        sx={{color}}
      />

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
          visibility: text.length > 0 ? "visible" : "hidden",
        }}
      >
        <IconButton onClick={handleClose}>
          <Close fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
}
