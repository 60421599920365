import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
  Box,
  Paper,
} from "@mui/material";

import useFetchVisioModel from "Features/visio/hooks/useFetchVisioModel";

export default function DialogCreateInputFromVisio({open, onClose}) {
  // strings

  const title = "Importer un plan visio";

  const importS = "Importer";

  // effect

  const {visioModel, loading} = useFetchVisioModel();

  // helpers

  const pdfUrl = visioModel?.visioFileRemoteUrl;

  // handlers

  async function handleClick() {
    console.log("visioModel", visioModel);
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth={false}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Paper sx={{width: 700, height: 500}}>
          {pdfUrl && (
            <iframe
              src={pdfUrl}
              type="application/pdf"
              width="100%"
              height="100%"
            />
          )}
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>{importS}</Button>
      </DialogActions>
    </Dialog>
  );
}
