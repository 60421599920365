export default function remoteToStateSurvey(survey) {
  const _survey = {...survey};
  try {
    // scene
    _survey.sceneId =
      typeof survey.scene === "object" ? survey.scene?.id : survey.scene;
    delete _survey.scene;

    // scope // null is an object...
    _survey.scopeId =
      typeof survey.scope === "object" ? survey.scope?.id : survey.scope;
    delete _survey.scope;

    // createdBy
    _survey.createdById =
      typeof survey.createdBy === "object"
        ? survey.createdBy?.id
        : survey.createdBy;

    // assignedTo
    _survey.assignedToEmail =
      typeof survey.assignedTo === "object"
        ? survey.assignedTo?.email
        : survey.assignedTo;

    _survey.assignedToId =
      typeof survey.assignedTo === "object"
        ? survey.assignedTo?.email
        : survey.assignedTo;

    return _survey;
  } catch (e) {
    console.log("Error in remoteToStateSurvey", e);
    return _survey;
  }
}
