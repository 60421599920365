import {useSelector} from "react-redux";

export default function useUserOrganisation() {
  const profileData = useSelector((s) => s.profile.data);
  console.log("profileData", profileData);
  //const organisations = useSelector((s) => s.organisations.items);
  //return organisations.find((org) => org.id === profileData.organisationId);
  const organisationId = profileData.organisationId;
  const organisationName = profileData.organisationName;
  const bimboxModes = profileData.bimboxModes;
  //
  return {id: organisationId, name: organisationName, bimboxModes};
}
