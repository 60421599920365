import theme from "Styles/theme";

export default class RoomsManager {
  constructor({webViewer, annotationsManager}) {
    this.webViewer = webViewer;
    this.annotationsManager = annotationsManager;

    this.defaultColor = theme.palette.secondary.main;

    this.selectedRoomsIds = [];
  }

  // POLYGONS

  addRoomPolygonAnnot(roomPolygon, roomId) {
    const {Annotations, annotationManager} = this.webViewer.Core;
    const {x, y, path, p, id} = roomPolygon;
    if (id) {
      const annotColor = this.annotationsManager.colorToAnnotationColor(
        this.defaultColor
      );

      const annot = new Annotations.PolygonAnnotation();
      annot.Id = id;
      annot.PageNumber = p ?? 1;
      path.forEach((point, index) =>
        annot.setPathPoint(index, point[0], point[1])
      );
      annot.StrokeColor = annotColor;
      annot.StrokeThickness = 0.5;
      annot.FillColor = annotColor;
      annot.Opacity = 0.3;

      annot.setCustomData("type", "ROOM_POLYGON");
      annot.setCustomData("roomId", roomId);

      annotationManager.addAnnotation(annot);
    } else {
      console.log("ERRROR: no id for roomPolygon", roomPolygon);
    }
  }

  addRoomPolygonsAnnots(roomPolygons, roomId) {
    roomPolygons.forEach((roomPolygon) =>
      this.addRoomPolygonAnnot(roomPolygon, roomId)
    );
  }

  addRoomsAnnots(rooms) {
    rooms.forEach((room) =>
      this.addRoomPolygonsAnnots(room.polygons ?? [], room.id)
    );
  }

  loadRooms() {}

  initRoomsAnnotations(rooms) {
    try {
      this.deleteAllRoomsAnnotations();
      this.addRoomsAnnots(rooms);
    } catch (e) {
      console.log("error", e);
    }
  }

  initRoomLabelsAnnotations(roomLabels) {
    try {
      this.deleteAllRoomLabelsAnnotations();
      this.addRoomLabelsAnnotations(roomLabels);
    } catch (e) {
      console.log("error", e);
    }
  }

  addRoomLabelsAnnotations(roomLabels) {
    roomLabels.forEach((roomLabel) => this.addRoomLabelAnnotation(roomLabel));
  }

  addRoomLabelAnnotation(roomLabel) {
    const {annotationManager, Annotations, documentViewer, Math} =
      this.webViewer.Core;
    const {p, bbox, label} = roomLabel;

    // annotations

    const padding = 10;

    const props = {
      PageNumber: p,
      X: bbox.x - padding,
      Y: bbox.y - padding,
      Width: bbox.width + padding * 2,
      Height: bbox.height + padding * 2,
      TextAlign: "center",
      TextVerticalAlign: "center",
      TextColor: new Annotations.Color(255, 0, 0, 1),
      StrokeColor: new Annotations.Color(0, 255, 0, 1),
      FillColor: new Annotations.Color(0, 255, 0, 0.1),
    };
    const annotation = new Annotations.FreeTextAnnotation(
      Annotations.FreeTextAnnotation.Intent.FreeText,
      props
    );

    //

    //annotation.setContents(label);
    annotation.setContents(".");
    annotation.setCustomData("type", "ROOM_LABEL");

    //

    annotationManager.addAnnotations([annotation]);
  }

  // SELECT
  setSelectedRooms(ids) {
    const idsToUnselect = this.selectedRoomsIds.filter(
      (id) => !ids.includes(id)
    );
    const idsToSelect = ids.filter((id) => !this.selectedRoomsIds.includes(id));
    //
    idsToUnselect.forEach((id) => this.unselectRoomAnnotation(id));
    idsToSelect.forEach((id) => this.selectRoomAnnotation(id));
    //
    this.selectedRoomsIds = ids;
  }
  selectRoomAnnotation(id) {
    const {annotationManager} = this.webViewer.Core;
    const annot = annotationManager.getAnnotationById(id);
    if (annot) {
      annotationManager.selectAnnotation(annot);
    }
  }
  unselectRoomAnnotation(id) {
    const {annotationManager} = this.webViewer.Core;
    const annot = annotationManager.getAnnotationById(id);
    if (annot) annotationManager.deselectAnnotation(annot);
  }

  // DELETE

  deleteAllRoomsAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    const annotations = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "ROOM_POLYGON");
    annotationManager.deleteAnnotations(annotations);
  }

  deleteAllRoomLabelsAnnotations() {
    const {annotationManager} = this.webViewer.Core;
    const annotations = annotationManager
      .getAnnotationsList()
      .filter((a) => a.getCustomData("type") === "ROOM_LABEL");
    annotationManager.deleteAnnotations(annotations);
  }

  focusOnRoomLabelAnnotation(id) {
    const {documentViewer} = this.webViewer.Core;
    const annotations = documentViewer
      .getAnnotationManager()
      .getAnnotationsList();
    const annotation = annotations.find((a) => a.Id === id);

    if (annotation) {
      const pageNumber = annotation.PageNumber;
      const zoom = documentViewer.getZoom();
      const x = annotation.X + annotation.Width / 2;
      const y = annotation.Y + annotation.Height / 2;

      documentViewer.setCurrentPage(pageNumber);
      documentViewer.zoomTo(zoom, {x, y});
    }
  }
}
