import useSceneModule from "Features/navigation/useSceneModule";

import {Box} from "@mui/material";

import SectionActionsInputs from "./SectionActionsInputs";

export default function SectionLeftPanelActionsInputs({scene, caplaEditor}) {
  // data

  const sceneModule = useSceneModule();

  // actions - main

  const actionsMainKeys = ["createInputs"];
  const actionsMoreKeys = ["createFromVisio"];

  return (
    <Box>
      {sceneModule === "INPUTS" && (
        <SectionActionsInputs
          scene={scene}
          caplaEditor={caplaEditor}
          actionsMainKeys={actionsMainKeys}
          actionsMoreKeys={actionsMoreKeys}
        />
      )}
    </Box>
  );
}
