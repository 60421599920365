// Maps are image models. They can be created from a mobile.

import {useSelector} from "react-redux";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";

export default function useMapsByScene(scene, options) {
  // options

  const filterByScope = options?.filterByScope;

  // data

  const models = useSelector((s) => s.viewer3D.models);
  let maps = models.filter(
    (m) => m.type === "IMAGE" && m.sceneClientId === scene?.clientId
  );
  const scope = useSelectedScope();

  // helpers

  if (filterByScope) {
    maps = maps.filter(
      (m) => !scope?.data?.mapIds || scope?.data?.mapIds?.includes(m.id)
    );
  }

  if (options?.sortByName) {
    maps.sort((a, b) => a.name.localeCompare(b.name));
  }

  return maps;
}
