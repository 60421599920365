import {useEffect, useState, useRef} from "react";

import {useDispatch, useSelector} from "react-redux";

import {
  updateRoom,
  setTempRoomPolygonProps,
  setIsDrawingRoom,
} from "../roomsSlice";

import useAccessToken from "Features/auth/useAccessToken";
import updateRoomPolygonsWithPolygon from "../utils/updateRoomPolygonsWithPolygon";

export default function useAutoUpdateRoomPolygons(room, caplaEditor) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const isSyncingRef = useRef(false);

  const tempRoomPolyonProps = useSelector((s) => s.rooms.tempRoomPolyonProps);
  console.log("[autoUpdateRoomPolygons]", tempRoomPolyonProps?.updatedAt);

  const [loading, setLoading] = useState(false);

  const updateAsync = async ({updates, accessToken}) => {
    try {
      setLoading(true);
      isSyncingRef.current = true;
      //
      const result = await dispatch(
        updateRoom({
          accessToken,
          updates,
        })
      );
      const {item} = result.payload;
      console.log("[update room]", item);
      caplaEditor.editorPdf.annotationsManager.stopDrawingRoom();
      //
      dispatch(setTempRoomPolygonProps({}));
      dispatch(setIsDrawingRoom(false));
      //
      setLoading(false);
      isSyncingRef.current = false;
    } catch (error) {
      console.error("useAutoUpdateRoomPolygons", error);
      setLoading(false);
      isSyncingRef.current = false;
    }
  };

  useEffect(() => {
    if (tempRoomPolyonProps?.updatedAt && !isSyncingRef.current) {
      const polygons = updateRoomPolygonsWithPolygon(
        room.polygon,
        tempRoomPolyonProps
      );
      const updates = {
        id: room.id,
        roomsGroupId: room.roomsGroupId,
        polygons,
      };
      updateAsync({updates, accessToken});
    }
  }, [tempRoomPolyonProps?.updatedAt]);

  return [loading];
}
