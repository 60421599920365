import {useSelector} from "react-redux";

import {Box} from "@mui/material";

import FormZone from "./FormZone";
import SectionHelperDrawZone from "./SectionHelperDrawZone";
import SectionZoneOverviewInLeftPanel from "./SectionZoneOverviewInSelectionPanel";

import useSelectedZone from "../hooks/useSelectedZone";
import useIsCreatingZone from "../hooks/useIsCreatingZone";
import useZoneFromImageModelId from "../hooks/useZoneFromImageModelId";

export default function SectionZoneInSelectionPanel({caplaEditor, scene}) {
  // strings

  // data

  const selectedZone = useSelectedZone(scene);
  const clickedObject = useSelector((s) => s.viewer3D.clickedObject);
  const clickedZone = useZoneFromImageModelId(scene, clickedObject?.modelId);

  const isEditingZone = useSelector((s) => s.zones.isEditingZone);
  const editedZone = useSelector((s) => s.zones.editedZone);

  const isCreatingZone = useIsCreatingZone();

  const groupCode = useSelector((s) => s.zones.selectedFormGroupCode);

  // helper
  let zone = selectedZone;
  if (clickedObject?.type === "IMAGE_ENTITY" && !zone) {
    zone = clickedZone;
  }
  if (isEditingZone) zone = editedZone;

  console.log("zoneAAA", zone);

  return (
    <Box>
      {zone && !isCreatingZone && (
        <FormZone caplaEditor={caplaEditor} scene={scene} zone={zone} />
      )}
      {isCreatingZone && <SectionHelperDrawZone />}

      {groupCode === "OVERVIEW" && (
        <SectionZoneOverviewInLeftPanel
          zone={clickedZone}
          caplaEditor={caplaEditor}
        />
      )}
    </Box>
  );
}
