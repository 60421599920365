import {client} from "API/capla360";

export async function fetchVisioModelService({accessToken}) {
  const response = await client.get("/visio-models-fetch-last/", {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const visioModel = response.data;
  return visioModel;
}
