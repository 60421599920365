export default function useSceneModuleRelatedModulesMap() {
  const map = {
    ELEMENT_TYPES: [
      "RESSOURCES",
      "ARTICLES",
      "RELATIONS",
      "DIVIDER",
      "EDIT_MEASUREMENTS",
    ],
    RESSOURCES: ["ELEMENT_TYPES", "ARTICLES", "RELATIONS"],
    ARTICLES: ["ELEMENT_TYPES", "RESSOURCES", "RELATIONS"],
    RELATIONS: ["ELEMENT_TYPES", "RESSOURCES", "ARTICLES"],
    EDIT_MEASUREMENTS: ["PACKAGES", "ELEMENT_TYPES"],
    //
    SURVEYS: ["SAMPLES"],
    SAMPLES: ["SURVEYS"],
    //
    SECTORS: ["ROOMS"],
    ROOMS: ["SECTORS"],
    //
    HOME: ["SCOPES"],
    SCOPES: ["SURVEYS", "SHAREDBOXES"],
    SHARED_BIMBOXES: ["SCOPES"],
    //
    REPORT: ["REPORTS"],
  };
  return map;
}
