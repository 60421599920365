import {useEffect} from "react";
import {useSelector} from "react-redux";

import useRooms from "Features/rooms/hooks/useRooms";

export default function useAutoUpdateRoomsPdfAnnotations(caplaEditor) {
  const roomsAnnotationsUpdatedAt = useSelector(
    (s) => s.rooms.roomsAnnotationsUpdatedAt
  );
  const pdfModelId = useSelector((s) => s.viewer3D.selectedPdfModelId);

  const rooms = useRooms({filterByPdfModelId: pdfModelId});

  // helpers

  const roomsManager = caplaEditor?.editorPdf?.roomsManager;

  useEffect(() => {
    if (roomsManager) {
      roomsManager.initRoomsAnnotations(rooms);
    }
  }, [roomsManager, pdfModelId, roomsAnnotationsUpdatedAt]);
}
