import {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {unwrapResult} from "@reduxjs/toolkit";
import {createScope, setSelectedScopeId, updateScope} from "../scopesSlice";

import usePackagesByScene from "Features/elementPackages/usePackagesByScene";
import useSelectedScope from "../hooks/useSelectedScope";

import useRoomsGroups from "Features/rooms/hooks/useRoomsGroups";
import useMaterialsGroupsByScene from "Features/materials/hooks/useMaterialsGroupsByScene";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";

import useAccessToken from "Features/auth/useAccessToken";
import useInputsByScene from "Features/inputs/hooks/useInputsByScene";
import useZonesByScene from "Features/zones/hooks/useZonesByScene";
import useElementTypesGroupsProxyByScene from "Features/elementTypes/hooks/useElementTypesGroupsProxyByScene";
import useRessourcesGroupsProxyByScene from "Features/ressources/hooks/useRessourcesGroupsProxy";

import HeaderTitleCloseGeneric from "Components/HeaderTitleCloseGeneric";
import SectionSelectorEntitiesInScope from "./SectionSelectorEntitiesInScope";

import getItemsMapById from "Utils/getItemsMapById";
import useSurveysByScene from "Features/surveys/hooks/useSurveysByScene";
import useArticlesGroups from "Features/articles/hooks/useArticlesGroups";
import useMapsByScene from "Features/maps/useMapsByScene";

export default function DialogFsCreateAndUpdateScope({
  open,
  onClose,
  scene,
  //mode,
}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  //
  // mode ="CREATE" // "UPDATE"
  //
  const selectedScope = useSelectedScope({inModule: true});
  const mode = selectedScope ? "UPDATE" : "CREATE";
  if (open) console.log("selectedScope", selectedScope);

  // strings

  const title1 = "Nouvelle sous-bimbox";
  const title2 = "Mise à jour de la sous-bimbox";

  const title = mode === "CREATE" ? title1 : title2;

  const nameS = "Nom";
  const clientReferenceS = "Référence interne";
  const descriptionS = "Description";

  const createS = "Créer";
  const updateS = "Mettre à jour";
  const editS = mode === "CREATE" ? createS : updateS;

  // data

  const surveys = useSurveysByScene(scene, {sortByName: true});
  const roomsGroups = useRoomsGroups(scene, {sortByName: true});
  const materialsGroups = useMaterialsGroupsByScene(scene, {sortByName: true});
  const packages = usePackagesByScene(scene, {sortByName: true});
  const inputs = useInputsByScene(scene, {sortByName: true});
  const maps = useMapsByScene(scene, {sortByName: true});
  const typesGroups = useElementTypesGroupsProxyByScene(scene, {
    sortByName: true,
  });
  const ressourcesGroups = useRessourcesGroupsProxyByScene(scene, {
    sortByName: true,
  });
  const articlesGroups = useArticlesGroups(scene?.id, {sortByName: true});

  const lastUpdatedAt = useSelector((s) => s.scopes.lastUpdatedAt);

  // state

  const [name, setName] = useState("");
  const [clientReference, setClientReference] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const [surveysIds, setSurveysIds] = useState([]);
  const [roomsGroupsIds, setRoomsGroupsIds] = useState([]);
  const [materialsGroupsIds, setMaterialsGroupsIds] = useState([]);
  const [inputIds, setInputIds] = useState([]);
  const [mapIds, setMapIds] = useState([]);
  const [packageIds, setPackageIds] = useState([]);
  const [typesGroupIds, setTypesGroupIds] = useState([]);
  const [ressourcesGroupIds, setRessourcesGroupIds] = useState([]);
  const [articlesGroupsIds, setArticlesGroupsIds] = useState([]);

  // helpers - fixedPdfModelIds

  const fixedPdfModelIds = [...inputIds];
  const packageMap = getItemsMapById(packages);
  packageIds.forEach((packageId) => {
    const model = packageMap[packageId];
    const pdfId = model?.fromModel?.modelId;
    if (pdfId && !fixedPdfModelIds.includes(pdfId)) {
      fixedPdfModelIds.push(pdfId);
    }
  });

  // helpers - inputMap

  const inputMap = getItemsMapById(inputs);

  // helpers - fixedMapIds

  let fixedMapIds = [...mapIds];
  fixedPdfModelIds.forEach((inputId) => {
    const pdfModel = inputMap[inputId];
    let zones = pdfModel?.zones;
    if (zones?.length > 0) {
      zones.forEach((zone) => {
        fixedMapIds.push(zone.imageModelId);
      });
    }
  });
  fixedMapIds = [...new Set(fixedMapIds)];

  // helpers - zoneIds

  let zoneIds = [];
  fixedPdfModelIds.forEach((inputId) => {
    const pdfModel = inputMap[inputId];
    let zones = pdfModel?.zones;
    if (zones?.length > 0) {
      zones.forEach((zone) => {
        zoneIds.push(zone.imageModelId);
      });
    }
  });

  // updates

  useEffect(() => {
    if (selectedScope?.id && mode === "UPDATE") {
      setName(selectedScope.name);
      setClientReference(selectedScope.clientReference ?? "");
      setDescription(selectedScope.description);
      //
      setSurveysIds(selectedScope.data?.surveysIds ?? []);
      setMaterialsGroupsIds(selectedScope.data?.materialsGroupsIds ?? []);
      setRoomsGroupsIds(selectedScope.data?.roomsGroupsIds ?? []);
      setPackageIds(selectedScope.data?.packageIds ?? []);
      setInputIds(selectedScope.data?.inputIds ?? []);
      setMapIds(selectedScope.data?.mapIds ?? []);
      setTypesGroupIds(selectedScope.data?.typesGroupIds ?? []);
      setRessourcesGroupIds(selectedScope.data?.ressourcesGroupIds ?? []);
      setArticlesGroupsIds(selectedScope.data?.articlesGroupsIds ?? []);
    }
  }, [lastUpdatedAt, selectedScope?.id, mode]);

  // handlers selection

  function handleSurveysSelectionChange(ids) {
    setSurveysIds(ids);
  }
  function handleRoomsGroupsSelectionChange(ids) {
    setRoomsGroupsIds(ids);
  }
  function handleMaterialsGroupsSelectionChange(ids) {
    setMaterialsGroupsIds(ids);
  }
  function handlePackageSelectionChange(ids) {
    setPackageIds(ids);
  }
  function handleInputsSelectionChange(ids) {
    setInputIds(ids);
  }
  function handleMapsSelectionChange(ids) {
    setMapIds(ids);
  }
  function handleTypesGroupSelectionChange(ids) {
    setTypesGroupIds(ids);
  }
  function handleRessourcesGroupSelectionChange(ids) {
    setRessourcesGroupIds(ids);
  }
  function handleArticlesGroupsSelectionChange(ids) {
    setArticlesGroupsIds(ids);
  }

  // handlers create

  async function handleCreate() {
    if (loading) return;
    try {
      setLoading(true);
      //
      const newScope = {};
      newScope.name = name;
      newScope.description = description;
      newScope.clientReference = clientReference;
      newScope.sceneId = scene?.id;
      newScope.data = {
        surveysIds,
        roomsGroupsIds,
        materialsGroupsIds,
        typesGroupIds,
        ressourcesGroupIds,
        articlesGroupsIds,
        packageIds,
        inputIds: fixedPdfModelIds,
        zoneIds,
        mapIds: fixedMapIds,
      };
      //
      console.log("creating newScope", newScope);
      //
      const result = await dispatch(
        createScope({scope: newScope, accessToken})
      );
      const {item} = unwrapResult(result);
      dispatch(setSelectedScopeId(item.id));
      //
      setLoading(false);
    } catch (e) {
      console.log("error", e);
      setLoading(false);
    }

    onClose();
  }

  async function handleUpdate() {
    if (loading) return;
    setLoading(true);
    //
    const updatedScope = {...selectedScope};
    updatedScope.name = name;
    updatedScope.clientReference = clientReference;
    updatedScope.description = description;
    updatedScope.data = {
      ...updatedScope.data,
      surveysIds,
      roomsGroupsIds,
      materialsGroupsIds,
      typesGroupIds,
      ressourcesGroupIds,
      packageIds,
      inputIds: fixedPdfModelIds,
      zoneIds,
      mapIds: fixedMapIds,
      articlesGroupsIds,
    };
    //
    console.log("updatedScope", updatedScope);
    //
    const result = await dispatch(
      updateScope({scope: updatedScope, accessToken})
    );
    //
    setLoading(false);
    onClose();
  }

  function handleCloseDialog() {
    onClose();
  }

  return (
    <Dialog open={open} onClose={handleCloseDialog} maxWidth={false} fullScreen>
      <HeaderTitleCloseGeneric onClose={handleCloseDialog} title={title} />
      <DialogContent
        sx={{
          display: "flex",
          justifyContent: "column",
          justifyContent: "stretch",
          height: 500,
          p: 0,
          m: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "stretch",
          }}
        >
          <Box
            sx={{
              display: "flex",
              p: 2,
              alignItems: "start",
              width: 1,
              justifyContent: "stretch",
            }}
          >
            <TextField
              label={<Typography variant="body2">{nameS}</Typography>}
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              size="small"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {scene?.clientReference && (
              <TextField
                sx={{ml: 2}}
                label={
                  <Typography variant="body2">{clientReferenceS}</Typography>
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography
                        sx={{
                          fontSize: (theme) => theme.typography.body2.fontSize,
                        }}
                      >{`# ${scene.clientReference} -`}</Typography>
                    </InputAdornment>
                  ),
                  sx: {fontSize: (theme) => theme.typography.body2.fontSize},
                }}
                size="small"
                value={clientReference}
                onChange={(e) => setClientReference(e.target.value)}
              />
            )}
            <TextField
              sx={{ml: 2, flexGrow: 1}}
              multiline
              label={<Typography variant="body2">{descriptionS}</Typography>}
              InputProps={{
                sx: {fontSize: (theme) => theme.typography.body2.fontSize},
              }}
              size="small"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Box>

          <Box
            sx={{
              pt: 2,
              display: "flex",
              flexGrow: 1,
              flexDirection: "column",
              minHeight: 0,
            }}
          >
            <SectionSelectorEntitiesInScope
              scene={scene}
              //
              surveys={surveys}
              selectionSurveys={surveysIds}
              onSelectionSurveysChange={handleSurveysSelectionChange}
              //
              roomsGroups={roomsGroups}
              selectionRoomsGroups={roomsGroupsIds}
              onSelectionRoomsGroupsChange={handleRoomsGroupsSelectionChange}
              //
              materialsGroups={materialsGroups}
              selectionMaterialsGroups={materialsGroupsIds}
              onSelectionMaterialsGroupsChange={
                handleMaterialsGroupsSelectionChange
              }
              //
              typesGroups={typesGroups}
              selectionTypesGroups={typesGroupIds}
              onSelectionTypesGroupsChange={handleTypesGroupSelectionChange}
              //
              ressourcesGroups={ressourcesGroups}
              selectionRessourcesGroups={ressourcesGroupIds}
              onSelectionRessourcesGroupsChange={
                handleRessourcesGroupSelectionChange
              }
              //
              packages={packages}
              selectionPackages={packageIds}
              onSelectionPackagesChange={handlePackageSelectionChange}
              //
              inputs={inputs}
              selectionInputs={inputIds}
              onSelectionInputsChange={handleInputsSelectionChange}
              //
              maps={maps}
              selectionMaps={fixedMapIds}
              onSelectionMapsChange={handleMapsSelectionChange}
              //
              articlesGroups={articlesGroups}
              selectionArticlesGroups={articlesGroupsIds}
              onSelectionArticlesGroupsChange={
                handleArticlesGroupsSelectionChange
              }
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={mode === "CREATE" ? handleCreate : handleUpdate}>
          {editS}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
