import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";

import {
  setIsEditingRoomsGroup,
  setSelectedRoomsGroupId,
  createRoomsGroup,
  updateRoomsGroup,
} from "../roomsSlice";

import useRoomsGroupInSelectionPanel from "../hooks/useRoomsGroupInSelectionPanel";

import {Box, Button, alpha} from "@mui/material";
import {Save} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";

export default function ActionsRoomsGroupInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  // strings

  const createS = "Créer";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const isEditing = useSelector((s) => s.rooms.isEditingRoomsGroup);
  const roomsGroup = useRoomsGroupInSelectionPanel();
  const selectedScopeId = useSelector((s) => s.scopes.selectedScopeId);

  // state

  const [loading, setLoading] = useState(false);

  // helper

  const isNew = !roomsGroup?.id;

  // helper - saveLabel

  const saveLabel = isNew ? createS : saveS;

  // helpers - functions

  async function _createRoomsGroup() {
    if (loading) return;
    try {
      setLoading(true);
      const newRoomsGroup = {...roomsGroup, sceneId: scene?.id};
      const {item} = await dispatch(
        createRoomsGroup({
          accessToken,
          roomsGroup: newRoomsGroup,
          fromScopeId: selectedScopeId,
        })
      );
      if (item) dispatch(setSelectedRoomsGroupId(item.id));
      setLoading(false);
    } catch (error) {
      console.error("[ActionsRoomsGroupInSelectionPanel] error", error);
      setLoading(false);
    }
  }

  async function _updateRoomsGroup() {
    if (loading) return;
    setLoading(true);
    await dispatch(updateRoomsGroup({accessToken, updates: roomsGroup}));
    dispatch(setIsEditingRoomsGroup(false));
    setLoading(false);
  }

  // handlers

  function handleCancelClick() {
    dispatch(setIsEditingRoomsGroup(false));
  }

  async function handleSaveClick() {
    if (isNew) {
      await _createRoomsGroup();
    } else {
      await _updateRoomsGroup();
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {(isEditing || isNew) && (
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            "&>*:not(:last-child)": {mr: 1},
          }}
        >
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleCancelClick}
          >
            {cancelS}
          </Button>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
}
