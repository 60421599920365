import {useSelector} from "react-redux";

export default function useKvtScene(data) {
  //
  const bimboxModes = data?.bimboxModes ?? ["BASIC"];
  const allOptions = [
    {key: "BASIC", name: "Basique"},
    {key: "STANDARD", name: "Standard"},
    {key: "PRO", name: "Pro"},
    {key: "EXPERT", name: "Expert"},
    {key: "DIAG", name: "Diagnostic"},
  ];
  const bimboxModeOptions = allOptions.filter((o) =>
    bimboxModes.includes(o.key)
  );
  //
  const profileData = useSelector((s) => s.profile.data);
  const organisation = {
    id: profileData.organisationId,
    name: profileData.organisationName,
  };
  //
  console.log("organisation", organisation);

  const kvt = {
    key: "scene",
    fields: [
      {
        key: "organisation",
        name: "Organisation",
        type: "combo",
        options: [organisation],
      },
      {
        key: "imageUrl",
        name: "Image",
        type: "image",
        container: "scene-image",
        blobPrefix: "scene-image",
      },
      {
        key: "identification",
        type: "section",
        name: "Identification",
      },
      {
        key: "title",
        name: "Nom",
        type: "text",
      },
      {
        key: "clientReference",
        type: "text",
        name: "Réf. interne",
      },

      {
        key: "description",
        name: "Description",
        type: "text",
      },
      {
        key: "usage",
        type: "section",
        name: "Usage",
      },
      {
        key: "isTest",
        type: "checkbox",
        name: "Test",
      },
      {
        key: "bimboxMode",
        type: "selector",
        name: "Mode",
        options: bimboxModeOptions,
      },
      {
        key: "address",
        type: "section",
        name: "Adresse",
      },
      {
        key: "addressL1",
        name: "Ligne 1",
        type: "text",
        placeholder: "N° et rue",
      },
      {
        key: "addressL2",
        name: "Ligne 2",
        type: "text",
        placeholder: "Code postal et ville",
      },
      {
        key: "addressL3",
        name: "Ligne 3",
        type: "text",
        placeholder: "Complément",
      },
    ],
  };
  return kvt;
}
