import FieldSelector from "./FieldSelector";

export default function FieldCategoryCode({
  item,
  name,
  value,
  onChange,
  sizeKey,
}) {
  // helper

  const codesString = item?.materialCategory?.code || "AU";

  const codesOptions = codesString.split("/").map((code) => {
    code.trim();
    return {key: code, name: code};
  });

  console.log("[FieldCategoryCode] value", value, codesOptions);

  return (
    <FieldSelector
      name={name}
      value={value}
      onChange={onChange}
      options={codesOptions}
      sizeKey={sizeKey}
    />
  );
}
