import {useSelector} from "react-redux";

export default function useKvtSurvey() {
  const kvt = {
    key: "survey",
    fields: [
      {
        key: "scheduledAt",
        name: "Date",
        type: "date",
      },
      {
        key: "assignedToEmail",
        name: "Réalisée par",
        type: "text",
      },
      {
        key: "progress",
        type: "selector",
        name: "Avancement",
        options: [
          {key: "TODO", name: "A faire"},
          {key: "WIP", name: "En cours"},
          {key: "DONE", name: "Terminée"},
        ],
      },
      {
        key: "name",
        name: "Libellée",
        type: "text",
      },
    ],
  };
  return kvt;
}
