import {useDispatch} from "react-redux";
import {nanoid} from "@reduxjs/toolkit";

import {updateModel} from "Features/viewer3D/viewer3DSlice";

import useSelectedPdfModel from "../useSelectedPdfModel";
import {triggerRoomsAnnotationsUpdatedAt} from "Features/rooms/roomsSlice";

export default function useAddRoomLabelToPdf() {
  const dispatch = useDispatch();

  // data

  const pdfModel = useSelectedPdfModel();

  const oldRoomLabels = pdfModel?.roomLabels ?? [];

  const add = (roomLabel) => {
    //
    const newRoomLabel = {id: nanoid()};
    newRoomLabel.label = roomLabel.label;
    newRoomLabel.bbox = roomLabel.bbox;
    newRoomLabel.p = roomLabel.p;
    newRoomLabel.roomClientId = roomLabel.clientId;
    //
    console.log("add roomLabel", newRoomLabel);
    //
    const updatedModel = {
      id: pdfModel.id,
      roomLabels: [...oldRoomLabels, newRoomLabel],
    };
    dispatch(updateModel({updatedModel, sync: true}));
    dispatch(triggerRoomsAnnotationsUpdatedAt());
  };

  return add;
}
