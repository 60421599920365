import {useSelector} from "react-redux";

import useSelectedSurvey from "./useSelectedSurvey";

export default function useSurveyInSelectionPanel() {
  //
  const selectedSurvey = useSelectedSurvey();
  const editedSurvey = useSelector((s) => s.surveys.editedSurvey);
  const _newSurvey = useSelector((s) => s.surveys.newSurvey);
  const isEditingSurvey = useSelector((s) => s.surveys.isEditing);

  // helper - newSurvey

  const newSurvey = {..._newSurvey};
  if (!newSurvey.scheduledAt) {
    const date = new Date();
    newSurvey.scheduledAt = date.toISOString();
  }
  if (!newSurvey.progress) {
    newSurvey.progress = "TODO";
  }
  // helper - survey

  let survey = selectedSurvey ?? newSurvey;
  if (isEditingSurvey) survey = editedSurvey;

  // return

  return survey;
}
