import {useDispatch} from "react-redux";

import {Add} from "@mui/icons-material";

export default function useActionsInputsMap({scene, caplaEditor}) {
  const dispatch = useDispatch();

  // actions map

  const actionsMap = {
    createInputs: {
      icon: <Add fontSize="small" />,
      name: "Pdf",
      label: "Ajouter des PDFs",
    },
    createFromVisio: {
      label: "Importer depuis Visio",
    },
  };

  return actionsMap;
}
