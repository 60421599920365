import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {
  fetchSurveysService,
  createSurveyService,
  fetchSurveyService,
  updateSurveyService,
  deleteSurveyService,
} from "./services";

export const createSurvey = createAsyncThunk(
  "surveys/createSurvey",
  createSurveyService
);

export const fetchSurveys = createAsyncThunk(
  "surveys/fetchSurveys",
  fetchSurveysService
);

export const fetchSurvey = createAsyncThunk(
  "surveys/fetchSurvey",
  fetchSurveyService
);

export const updateSurvey = createAsyncThunk(
  "surveys/updateSurvey",
  updateSurveyService
);
export const deleteSurvey = createAsyncThunk(
  "surveys/deleteSurvey",
  deleteSurveyService
);

const surveysSlice = createSlice({
  name: "surveys",
  initialState: {
    items: [],
    surveysMap: {},
    selectedSurveyId: null,
    editedSurvey: {},
    isEditing: false,
    newSurvey: {},
  },
  reducers: {
    setSelectedSurveyId: (state, action) => {
      state.selectedSurveyId = action.payload;
    },
    setIsEditing: (state, action) => {
      state.isEditing = action.payload;
    },
    setEditedSurvey: (state, action) => {
      state.editedSurvey = action.payload;
    },
    setNewSurvey: (state, action) => {
      state.newSurvey = action.payload;
    },
  },
  extraReducers: {
    [createSurvey.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.surveysMap[item.id] = item;
    },
    [fetchSurveys.fulfilled]: (state, action) => {
      const {items} = action.payload;
      items.forEach((item) => {
        state.surveysMap[item.id] = item;
      });
    },
    [fetchSurvey.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.surveysMap[item.id] = item;
    },
    [updateSurvey.fulfilled]: (state, action) => {
      const {item} = action.payload;
      state.surveysMap[item.id] = item;
    },
    [deleteSurvey.fulfilled]: (state, action) => {
      const surveyId = action.payload;
      delete state.surveysMap[surveyId];
    },
  },
});
export const {
  setSelectedSurveyId,
  setIsEditing,
  setEditedSurvey,
  setNewSurvey,
} = surveysSlice.actions;

export default surveysSlice.reducer;
