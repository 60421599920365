export default function getUpdatesSurvey(oldSurvey, newSurvey) {
  const fields = [
    "name",
    "createdById",
    "scheduledAt",
    "assignedToEmail",
    "progress",
  ];

  const updates = {id: oldSurvey.id, sceneId: oldSurvey.sceneId};

  fields.forEach((field) => {
    if (oldSurvey[field] !== newSurvey[field]) {
      updates[field] = newSurvey[field];
    }
  });

  return updates;
}
