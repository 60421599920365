import {useSelector} from "react-redux";
import useSurveysByScene from "./useSurveysByScene";

export default function useSelectedSurvey() {
  const surveysMap = useSelector((s) => s.surveys.surveysMap);
  const id = useSelector((s) => s.surveys.selectedSurveyId);

  let survey = surveysMap[id];

  return survey;
}
