export default function getRoomsAnnotsIdsFromRoomsIds(roomsIds, options) {
  const roomsAnnotsIds = [];

  // options

  const roomsMap = options?.roomsMap ?? {};
  const pdfModelId = options?.pdfModelId;

  // loop

  roomsIds.forEach((roomId) => {
    const room = roomsMap[roomId];
    if (room?.polygons) {
      room.polygons.forEach((polygon) => {
        if (polygon.pdfModelId === pdfModelId) {
          roomsAnnotsIds.push(polygon.id);
        }
      });
    }
  });

  // result

  return roomsAnnotsIds;
}
