import {Box} from "@mui/material";
import KeyValueForm from "Features/kvform/components/KeyValueForm";
import useKvtSurvey from "Features/kvform/hooks/useKvtSurvey";
import NewItem from "Features/tree/components/NewItem";

export default function FormSurvey({survey, onChange}) {
  // data

  const template = useKvtSurvey();

  // handlers

  function handleChange(newItem) {
    const newSurvey = {...newItem};
    if (onChange) onChange(newSurvey);
  }

  // helpers

  const item = {...survey};

  return (
    <Box sx={{borderTop: (theme) => `1px solid ${theme.palette.divider}`}}>
      <KeyValueForm
        sizeKey={4}
        item={item}
        template={template}
        onChange={handleChange}
      />
    </Box>
  );
}
