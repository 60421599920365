import {client} from "API/capla360";
import remoteToStateSurvey from "./utils/remoteToStateSurvey";
import stateToRemoteSurvey from "./utils/stateToRemoteSurvey";

export async function fetchSurveysService({accessToken, sceneId}) {
  const response = await client.get(`/scenes/${sceneId}/surveys/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const sharedSurveys = response.data;
  console.log("fetchSurveysService 18", sharedSurveys);
  const surveys = sharedSurveys.map(remoteToStateSurvey);
  return {items: surveys, sceneId};
}

export async function createSurveyService({accessToken, survey, fromScopeId}) {
  const sceneId = survey.sceneId;
  const newSurvey = stateToRemoteSurvey(survey);
  const response = await client.post(`scenes/${sceneId}/surveys/`, newSurvey, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    params: {
      fromScope: fromScopeId,
    },
  });
  const remoteSurvey = response.data;
  const item = remoteToStateSurvey(remoteSurvey);
  return {item, fromScopeId};
}

export async function fetchSurveyService({accessToken, sceneId, surveyId}) {
  const response = await client.get(`/scenes/${sceneId}/surveys/${surveyId}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  const remoteSurvey = response.data;
  const item = remoteToStateSurvey(remoteSurvey);
  return {item, sceneId};
}

export async function updateSurveyService({accessToken, updates}) {
  const sceneId = updates.sceneId;
  const surveyId = updates.id;
  const updatesForRemote = stateToRemoteSurvey(updates);
  const response = await client.patch(
    `/scenes/${sceneId}/surveys/${surveyId}/`,
    updatesForRemote,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );
  const updatedSurvey = response.data;
  const item = remoteToStateSurvey(updatedSurvey);
  return {item};
}

export const deleteSurveyService = async ({accessToken, surveyId, sceneId}) => {
  await client.delete(`/scenes/${sceneId}/surveys/${surveyId}/`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  return surveyId;
};
