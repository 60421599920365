import {Box, Paper} from "@mui/material";

import ButtonEditRoomInPdf from "./ButtonEditRoomInPdf";

import useAutoUpdateRoomPolygons from "../hooks/useAutoUpdateRoomPolygons";
import useSelectedRoom from "../hooks/useSelectedRoom";

export default function ToolbarPdfRooms({caplaEditor, scene}) {
  // data

  const room = useSelectedRoom();

  // effect

  const [loading] = useAutoUpdateRoomPolygons(room, caplaEditor);

  return (
    <Paper
      elevation={12}
      sx={{
        display: "flex",
        alignItems: "center",
        bgcolor: "common.caplaBlack",
        color: "common.white",
      }}
    >
      {room && (
        <ButtonEditRoomInPdf caplaEditor={caplaEditor} loading={loading} />
      )}
    </Paper>
  );
}
