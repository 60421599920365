import {useState} from "react";

import {nanoid} from "@reduxjs/toolkit";

export default function useCreateImageModelFromZone(caplaEditor) {
  const [loading, setLoading] = useState(false);

  async function createImageModel(zone) {
    try {
      if (loading) return;
      setLoading(true);
      const id = nanoid();
      const file =
        await caplaEditor?.editorPdf?.annotationsManager.getAnnotationImageFile(
          zone.id
        );
      await caplaEditor?.editorPdf?.annotationsManager.createImageModelFromZone(
        zone,
        file
      );
      setLoading(false);
      //
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  }

  return [createImageModel, loading];
}
