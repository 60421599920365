import {useState} from "react";
import {useSelector} from "react-redux";

import {
  Box,
  IconButton,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import {
  Refresh,
  ExpandMore as More,
  ExpandLess as Less,
} from "@mui/icons-material";

import ImageBasic from "Features/images/components/ImageBasic";
import {getImageSize} from "Features/images/imageUtils";
import useCreateImageModelFromZone from "../hooks/useCreateImageModelFromZone";

export default function SectionZoneImage({zone, caplaEditor}) {
  // strings

  const imageS = "Image";
  const createS = "Créer le fond de plan";

  // data

  const models = useSelector((s) => s.viewer3D.models);
  const imageModel = models.find((m) => m.id === zone.imageModelId);

  // data - func

  const [createImageModelFromZone, isCreating] =
    useCreateImageModelFromZone(caplaEditor);

  // helpers

  const noImage = !imageModel;

  const imageUrl = imageModel?.fileRemoteUrl
    ? imageModel.fileRemoteUrl
    : zone?.imageUrl;

  // state

  const [loading, setLoading] = useState(false);
  const [expand, setExpand] = useState(true);

  // helper  - icon

  let icon = <Refresh sx={{fontSize: 13}} />;
  if (loading)
    icon = <CircularProgress size={13} sx={{color: "common.white"}} />;

  // handlers

  async function handleCreateClick() {
    createImageModelFromZone(zone);
  }

  async function handleResetClick() {
    if (loading || !imageModel) return;
    setLoading(true);
    const file =
      await caplaEditor?.editorPdf?.annotationsManager.getAnnotationImageFile(
        zone.id
      );
    const updatedModel = {id: imageModel.id};
    await caplaEditor?.editor3d?.loader.updateImageModel({updatedModel, file}); // the updateAnnotationEntityProps is calling as well the loader.updateImageModel=> 2 calls of updating Remote model

    // image size
    const imageSize = await getImageSize({file});
    const annotationId = zone.id;
    const props = {imageSize};
    caplaEditor?.editorPdf?.annotationsManager.updateAnnotationEntityProps({
      annotationId,
      props,
      updateImageModel: false, // to prevent the updateImageModel to be called (outofdata model.url)
    });

    setLoading(false);
  }

  function toggleExpand() {
    setExpand((expand) => !expand);
  }

  return (
    <Box sx={{width: 1}}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          bgcolor: "background.default",
          width: 1,
          px: 1,
        }}
      >
        <Box sx={{display: "flex", alignItems: "center"}}>
          <Typography sx={{fontSize: 13, fontWeight: "bold", mr: 1}}>
            {imageS}
          </Typography>

          <IconButton size="small" onClick={toggleExpand}>
            {expand ? (
              <Less sx={{fontSize: 13}} />
            ) : (
              <More sx={{fontSize: 13}} />
            )}
          </IconButton>
        </Box>
        {!noImage && (
          <IconButton size="small" onClick={handleResetClick}>
            {icon}
          </IconButton>
        )}
      </Box>
      {expand && (
        <Box
          sx={{
            width: 1,
            height: 150,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {noImage ? (
            <Button
              disabled={isCreating}
              onClick={handleCreateClick}
              color="primary"
              variant="contained"
            >
              {createS}
            </Button>
          ) : (
            <ImageBasic url={imageUrl} />
          )}
        </Box>
      )}
    </Box>
  );
}
