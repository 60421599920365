import {List, ListItemButton, Typography, Box} from "@mui/material";

import formatDate from "../utils/formatDate";

export default function ListSurveys({surveys, selection, onSelectionChange}) {
  // helpers

  const progressSMap = {TODO: "A faire", WIP: "En cours", DONE: "Fait"};

  // handlers

  function handleClick(survey) {
    if (selection === survey.id) {
      onSelectionChange(null);
    } else {
      onSelectionChange(survey.id);
    }
  }
  return (
    <List dense>
      {surveys.map((survey) => {
        const selected = selection === survey.id;
        const formatedDate = survey.scheduledAt
          ? formatDate(survey.scheduledAt)
          : "-";
        const progressS = progressSMap[survey.progress] ?? "-?-";
        return (
          <ListItemButton
            divider
            selected={selected}
            onClick={() => {
              console.log("clicked on survey", survey);
              handleClick(survey);
            }}
          >
            <Box sx={{width: 1}}>
              <Typography variant="body2">{survey.name}</Typography>
              <Typography sx={{fontSize: 12, color: "text.secondary"}}>
                {formatedDate}
              </Typography>
              <Box sx={{width: 1, display: "flex", justifyContent: "end"}}>
                <Typography sx={{fontSize: 13}}>{progressS}</Typography>
              </Box>
            </Box>
          </ListItemButton>
        );
      })}
    </List>
  );
}
