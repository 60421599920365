import {useDispatch, useSelector} from "react-redux";

import {setIsEditing, setEditedSurvey, setNewSurvey} from "../surveysSlice";
import {Box} from "@mui/material";

import FormSurvey from "./FormSurvey";
import useSurveyInSelectionPanel from "../hooks/useSurveyInSelectionPanel";

export default function SectionSurveyInSelectionPanel({scene, viewer}) {
  const dispatch = useDispatch();
  // data

  const survey = useSurveyInSelectionPanel();

  // helpers

  const isNew = !survey?.id;

  // handlers

  function handleChange(changedSurvey) {
    if (isNew) {
      dispatch(setNewSurvey(changedSurvey));
    } else {
      dispatch(setEditedSurvey(changedSurvey));
      dispatch(setIsEditing(true));
    }
  }

  return (
    <Box sx={{width: 1, height: 1, overflow: "auto"}}>
      <FormSurvey survey={survey} onChange={handleChange} />
    </Box>
  );
}
