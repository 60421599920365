import {nanoid} from "@reduxjs/toolkit";

export default function updateRoomPolygonsWithPolygon(polygons, polygon) {
  let isNew = true;

  // edge case

  if (!polygons) {
    return [polygon];
  }

  // main case
  const newPolygons = polygons.map((p) => {
    if (p.id === polygon.id) {
      isNew = false;
      return polygon;
    } else {
      return p;
    }
  });
  if (isNew) {
    newPolygons.push(polygon);
  }
  return newPolygons;
}
