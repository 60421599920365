import {useState, useEffect} from "react";
import useAccessToken from "Features/auth/useAccessToken";
import {fetchVisioModelService} from "../servicesVisio";

export default function useFetchVisioModel() {
  const accessToken = useAccessToken();

  // state
  const [loading, setLoading] = useState(false);
  const [visioModel, setVisioModel] = useState(null); // handlers
  const [error, setError] = useState(null);

  async function fetchVisioModel() {
    try {
      setLoading(true);
      const visioModel = await fetchVisioModelService({accessToken});
      setVisioModel(visioModel);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (accessToken) {
      fetchVisioModel();
    }
  }, [accessToken]);

  return {
    loading,
    error,
    visioModel,
  };
}
