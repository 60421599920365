import {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {nanoid, unwrapResult} from "@reduxjs/toolkit";

import {
  setIsEditing,
  setSelectedSurveyId,
  createSurvey,
  updateSurvey,
} from "../surveysSlice";

import useSurveyInSelectionPanel from "../hooks/useSurveyInSelectionPanel";

import {Box, Button, alpha} from "@mui/material";
import {Edit, Save} from "@mui/icons-material";

import useAccessToken from "Features/auth/useAccessToken";
import useSelectedScope from "Features/scopes/hooks/useSelectedScope";
import formatDate from "../utils/formatDate";
import useSelectedSurvey from "../hooks/useSelectedSurvey";
import getUpdatesSurvey from "../utils/getUpdatesSurvey";

export default function ActionsSurveyInSelectionPanel({scene}) {
  const dispatch = useDispatch();
  const accessToken = useAccessToken();
  const organisationId = scene?.organisationId;

  // strings

  const createS = "Créer";
  const saveS = "Enregistrer";
  const cancelS = "Annuler";

  // data

  const isEditing = useSelector((s) => s.surveys.isEditing);
  const survey = useSurveyInSelectionPanel();
  const scope = useSelectedScope();
  const selectedSurvey = useSelectedSurvey(scene);

  // state

  const [loading, setLoading] = useState(false);

  // helper

  const isNew = !survey?.id;

  // helper - saveLabel

  const saveLabel = isNew ? createS : saveS;

  // helpers - functions

  async function create() {
    if (loading) return;
    try {
      setLoading(true);
      const newSurvey = {
        ...survey,
        scopeId: scope?.id,
        sceneId: scene?.id,
        name:
          survey?.name?.length > 0
            ? survey.name
            : formatDate(survey.scheduledAt),
      };
      console.log("create survey", newSurvey, formatDate(survey.scheduledAt));
      const result = await dispatch(
        createSurvey({
          accessToken,
          survey: newSurvey,
        })
      );
      const {item} = unwrapResult(result);
      dispatch(setSelectedSurveyId(item?.id));
      setLoading(false);
    } catch (error) {
      console.error("[ActionsSurveyInSelectionPanel] error", error);
      setLoading(false);
    }
  }

  async function update() {
    if (loading) return;
    setLoading(true);
    const updates = getUpdatesSurvey(selectedSurvey, survey);
    await dispatch(updateSurvey({accessToken, updates}));
    dispatch(setIsEditing(false));
    setLoading(false);
  }

  // handlers

  function handleCancelClick() {
    dispatch(setIsEditing(false));
  }

  async function handleSaveClick() {
    if (isNew) {
      await create();
    } else {
      await update();
    }
  }

  return (
    <Box
      sx={{
        width: 1,
        display: "flex",
        alignItems: "center",
        p: 1,
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        bgcolor: (theme) => alpha(theme.palette.common.white, 1),
        "&>*:not(:last-child)": {mr: 1},
      }}
    >
      {isEditing && (
        <Box sx={{flexGrow: 1}}>
          <Button variant="outlined" fullWidth onClick={handleCancelClick}>
            {cancelS}
          </Button>
        </Box>
      )}

      {(isEditing || isNew) && (
        <Box sx={{flexGrow: 1}}>
          <Button
            startIcon={<Save />}
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSaveClick}
          >
            {saveLabel}
          </Button>
        </Box>
      )}
    </Box>
  );
}
